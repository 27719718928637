import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230301-Rate-Sheet.pdf";
const date = "01.03.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Wednesday 01 March 2023 we will be making the following changes to our range:</p>
    <p>
      We are changing our Residential Standard Variable Rate from 6.79% to 6.99% and our Buy to Let Standard Variable
      Rate from 6.35% to 6.85%.
    </p>
    <p>
      <strong>Summary of changes</strong>
    </p>
    <p>
      <strong>UK Residential Existing Customer Switching Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 85% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
      <li>10 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Residential Existing Customer Borrowing More Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70%, 75% and 85% LTV have increased</li>
      <li>3 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>3 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 60%, 70%, and 75% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
      <li>10 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Residential First Time Buyer Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 90% LTV has increased</li>
      <li>2 Year Fixed Standard at 80% and 85% LTV have Increased</li>
      <li>5 Year Fixed Fee Saver at 90% LTV has Increased</li>
      <li>5 Year Fixed Standard at 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Residential Home Mover Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 90% LTV has increased</li>
      <li>2 Year Fixed Standard at 80% and 85% LTV have Increased</li>
      <li>5 Year Fixed Fee Saver at 90% LTV has Increased</li>
      <li>5 Year Fixed Standard at 80%, 85% and 90% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 80%, 85% and 90% LTV have increased</li>
    </ul>
    <p>
      <strong>UK Residential Remortgage Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Fee Saver at 60%, 70% and 75% LTV have increased</li>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>5 Year Fixed Fee Saver at 70%, 75%, 80% and 85% LTV have increased</li>
      <li>5 Year Fixed Standard at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
      <li>5 Year Fixed Premier Exclusive at 60%, 70%, 75%, 80% and 85% LTV have increased</li>
      <li>10 Year Fixed Fee Saver at 60%, 70%, 75% and 80% have increased</li>
      <li>10 Year Fixed Standard at 70% and 75% have increased</li>
    </ul>
    <p>
      <strong>International Residential Range</strong>
    </p>
    <ul>
      <li>2 Year Fixed Standard at 60%, 70% and 75% LTV have increased</li>
      <li>10 Year Fixed Fee Saver at 60, 70% and 75% LTV have increased</li>
      <li>10 Year Fixed Standard at 70% and 75% LTV have increased</li>
    </ul>
    <p>There are no changes to any other interest rates at this time.</p>
    <p>An updated mortgage rate sheet will be published on Wednesday 01 March to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
